<!--
 * @Descripttion: 海报列表
 * @version:
 * @Author: alex.yang
 * @Date: 2022-06-27 14:35:05
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-04-18 14:43:30
-->
<template>
  <div class="poster">
    <div class="title-wrap">
      <div class="tag"></div>
      <div class="title">海报文案</div>
    </div>
    <div class="case-wrap">
      <div class="table-wrap" >
        <div class="title-wrap">
          <div class="search-wrap">
            <div class="sw-item">
              <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
              <el-button @click="clickSearch" type="primary">搜索</el-button>
            </div>
          </div>
          <el-button @click="clickAdd" type="primary">添加文案</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
          <el-table-column align="center" prop="txt_content" label="文案" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template slot-scope="scope">
              <div class="operation-wrap">
                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                  <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-if="isShowImages">
        <el-image-viewer 
        :on-close="closeViewer" 
        :url-list="imageList" />
    </div>
    <!-- 上传海报 -->
    <el-dialog
      :visible.sync="isCreatePoster" 
      :before-close="handleClose"
      custom-class="dialog-wrap"
      :close-on-click-modal="false">
      <div class="title" v-if="editId<0">上传海报文案</div>
      <div class="title" v-else>编辑海报文案</div>
      <div class="content">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="海报文案" prop="txt_content">
              <vue-emoji width="605" height="150" @input="emojiInput" style="display: inline-block;" 
                ref="emoji" :value="ruleForm.txt_content" />
            </el-form-item>
        </el-form>
        <div class="btn-wrap">
            <el-button v-if="editId<0" :loading="btnLoading" @click="submitForm" type="primary">保 存</el-button>
            <el-button v-else :loading="btnLoading" @click="submitFormEdit" type="primary">编 辑</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import common from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import VueEmoji from 'emoji-vue'
export default {
  components:{
    ElImageViewer,
    AliyunUpload,
    VueEmoji
  },
  mixins:[pageMixin],
  data () {
    return {
      pageMixin_index:'posterListCopy',
      tableData: [],
      loading: false,
      keyword: '',
      dateType: '',
      startDate: '',
      endDate: '',
      currentPage: 1,
      total: 0,
      isShowImages: false,
      imageList: [],
      isCreatePoster: false,
      image: [],
      btnLoading: false,
      ruleForm: {
        txt_content: '',
      },
      emojiData: '',
      editId: -1,
      sort_by: '',
    }
  },
   watch: {
      image(newName){
        if(newName.length > 0){
          this.ruleForm.url = newName[0]
        }
      }
  },
  mounted () {
    this.getIndexList();
  },
  methods: {
    selectTypeChange(){
      this.currentPage = 1;
      this.getIndexList();
    },
    clickTop(row){
      common.connect("/customerservicev1/poster/top",{id: row.id},(res)=>{
          if(res.isSuccess == 1){
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getIndexList();
          }
      });
    },
    // 排序
    changeShow(item){
      this.sort_by = item;
      this.getIndexList();
    },
    // 添加海报
    submitForm(){
      this.ruleForm.txt_content = this.emojiData;
      if(this.ruleForm.txt_content == ''){
        this.$message.error('文案内容不能为空');
        return
      }
      if(this.ruleForm.txt_content.length > 40){
        this.$message.error('文案内容不能超过40个字符');
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            txt_content: this.ruleForm.txt_content,
          }
          this.btnLoading = true;
          common.connect("/customerservicev1/poster/storeWriting",params,(res)=>{
            this.$message({
                message: res.msg,
                type: 'success'
            });
            this.getIndexList();
            this.emojiData = '';
            this.image = [];
            this.keyword = '';
            this.$refs.ruleForm.resetFields();
            this.btnLoading = false;
            this.isCreatePoster = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 编辑提交
    submitFormEdit(){
      this.ruleForm.txt_content = this.emojiData;
      if(this.ruleForm.txt_content == ''){
        this.$message.error('文案内容不能为空');
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            id: this.editId,
            txt_content: this.ruleForm.txt_content,
          }
          this.btnLoading = true;
          common.connect("/customerservicev1/poster/updateWriting",params,(res)=>{
            this.$message({
                message: res.msg,
                type: 'success'
            });
            this.getIndexList();
            this.emojiData = '';
            this.editId = -1;
            this.ruleForm.txt_content = '';
            this.btnLoading = false;
            this.isCreatePoster = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击编辑
    clickEdit(row){
      this.editId = row.id;
      common.connect("/customerservicev1/poster/editWriting",{id: row.id},(res)=>{
        this.isCreatePoster = true;
        this.emojiData = res.data.txt_content;
        this.ruleForm.txt_content = res.data.txt_content;
      });
    },
    // 删除
    clickDel(row){
      this.$confirm('此操作将永久删除该海报文案, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          common.connect("/customerservicev1/poster/deleteWriting",{id: row.id},(res)=>{
              this.$message({
                  message: res.msg,
                  type: 'success'
              });
              this.getIndexList();
          });
      }).catch(() => {
          this.$message({
              type: 'info',
              message: '已取消删除'
          });          
      });
    },
    // 点击添加海报
    clickAdd(){
      this.isCreatePoster = true;
    },
    emojiInput(e){
      this.emojiData = e.data;
    },
    //删除添加海报图片
    delImageList(){
      this.image.splice(0, 1)
    },
    // 关闭弹窗
    handleClose(){
      this.isCreatePoster = false;
      this.emojiData = '';
      this.image = [];
      this.editId = -1;
      this.ruleForm.url = '';
      this.ruleForm.txt_content = '';
      this.ruleForm.type = '日常宣传';
      this.ruleForm.search_keys = '';
    },
    // 搜索
    clickSearch(){
      this.currentPage = 1;
      this.getIndexList()
    },
    // 获取列表数据
    getIndexList(){
      let params = {
        keyword: this.keyword,
        page: this.currentPage,
      }
      this.loading = true;
      common.connect("/customerservicev1/poster/writingIndex",params,(res)=>{
        this.total = res.data.count;
        this.tableData = res.data.list;
        this.loading = false;
      });
    },
    clickImage(item){
        this.isShowImages = true;
        this.imageList.push(item.url);
    },
    // 关闭查看器
    closeViewer() {
        this.isShowImages = false;
        this.imageList = [];
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getIndexList();
    }
  },
}
</script>

<style lang='scss'>
.poster{
  background: rgb(240, 242, 245);
  padding: 10px 25px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  .title-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0;
    .tag{
      width: 4px;
      height: 20px;
      background: #409EFF;
      border-radius: 10px;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .case-wrap{
    background: #fff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 6px;
    .table-wrap{
      .sort-wrap{
        cursor: pointer;
      }
      .title-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        .select-wrap{
          margin-right: 20px;
        }
        .search-wrap{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .sw-item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          .el-input{
            width: 200px;
            margin-right: 10px;
          }
        }
      }
      .pic{
        width: 40px;
        cursor: pointer;
      }
      .operation-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        .iconfont{
            font-size: 16px;
            padding: 0 6px;
            cursor: pointer;
        }
    }
    }
    .pagination-wrap{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;
    }
  }
  .dialog-wrap{
      width: 800px;
      .title{
        font-size: 16px;
        color: #000;
        margin-left: 20px;
        margin-top: -5px;
      }
      .content{
          margin-left: 20px;
          padding: 30px 0;
          .el-input{
              width: 650px;
          }
          .el-textarea{
              width: 650px;
          }
          .upload-wrap{
            padding-top: 2px;
            .fileList-box{
              width: 100px;
              height: auto;
              position: relative;
              .delFile{
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 1px #ddd solid;
                background: rgba(100, 100, 100, 0.5);
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 99;
              }
              .pic{
                width: 100%;
              }
            }
        }
      }
      .el-radio{
        margin-top: 13px;
      }
      .btn-wrap{
          display: flex;
          justify-content: center;
      }
  }
  .el-dialog{
      margin: 0;
      margin-top: 0vh !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      padding-bottom: 10px;
  }
}
</style>
